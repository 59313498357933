import React from "react";
import { BooleanInput, Filter, ReferenceInput, SelectInput, TextInput } from 'react-admin';

const InvoiceFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Amount" source="amountPaid" />
    <ReferenceInput label="Customer" source="customer" reference="customers">
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput label="Package" source="package" reference="packages">
      <SelectInput optionText="appleStoreProductName" />
    </ReferenceInput>
    <BooleanInput label="Succeeded" source="success" />
  </Filter>
);

export default InvoiceFilter;
