import { BooleanInput, Edit, NumberInput, SimpleForm, TextInput } from 'react-admin';
import React from 'react';
import RecordTitle from '../RecordTitle';
import EditActions from '../actions/EditActions';

const PackageEdit = props => (
  <Edit
    actions={<EditActions />}
    title={<RecordTitle type="Package" />}
    {...props}
  >
    <SimpleForm>
      <TextInput source="appleStoreProductName" />
      <TextInput source="description" />
      <BooleanInput source="hidden" />
      <NumberInput source="price" />
      <NumberInput source="minutesToUse" />
      <TextInput source="discount" />
    </SimpleForm>
  </Edit>
);

export default PackageEdit;
