import { Edit, SimpleForm, TextInput, PasswordInput } from 'react-admin';
import React from 'react';
import RecordTitle from '../RecordTitle';
import EditActions from '../actions/EditActions';

const AdminEdit = props => (
  <Edit
    actions={<EditActions />}
    title={<RecordTitle type="Admin" />}
    {...props}
  >
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="email" />
      <PasswordInput source="password" />
    </SimpleForm>
  </Edit>
);

export default AdminEdit;
