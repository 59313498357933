import React from "react";
import { Filter, ReferenceInput, SelectInput, TextInput } from 'react-admin';

const ConversationFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Mercure message" source="firstMercureMessageId" />
    <ReferenceInput label="Expert" source="expert" reference="experts">
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput label="Customer" source="customer" reference="customers">
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

export default ConversationFilter;
