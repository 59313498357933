import React from "react";
import { BooleanInput, Filter, TextInput } from 'react-admin';

const PackageFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Apple product" source="appleStoreProductName" />
    <TextInput label="Free minutes" source="minutesToUse" />
    <TextInput label="Price" source="price" />
    <BooleanInput label="Hidden" source="hidden" />
  </Filter>
);

export default PackageFilter;
