import React from 'react';
import { BooleanField, EditButton, Datagrid, List, ShowButton, TextField } from 'react-admin';
import ReceiptIcon from '@material-ui/icons/Receipt';
import PackageFilter from '../filter/PackageFilter';
import FilteredLink from '../FilteredLink';

const PackageList = props => (
  <List {...props} filters={<PackageFilter />}>
    <Datagrid>
      <TextField source="appleStoreProductName" />
      <TextField source="description" sortable={false} />
      <BooleanField source="hidden" />
      <TextField source="price" />
      <TextField source="minutesToUse" />
      <TextField source="discount" />
      <FilteredLink filterBy="package" link="invoices" icon={<ReceiptIcon />} />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

export default PackageList;
