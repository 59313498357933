import React from 'react';
import { SelectInput } from 'react-admin';

const GenderInput = () => (
  <SelectInput source="gender" choices={[
    { id: null, name: 'Not set' },
    { id: 'male', name: 'Male' },
    { id: 'female', name: 'Female' },
  ]} />
);

export default GenderInput;
