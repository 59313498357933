import { SimpleShowLayout, Show, TextField } from 'react-admin';
import React from 'react';
import RecordTitle from '../RecordTitle';
import ShowActions from '../actions/ShowActions';

const CategoryShow = props => (
  <Show
    title={<RecordTitle type="Category" />} {...props}
    actions={<ShowActions />}
  >
    <SimpleShowLayout>
      <TextField addLabel={true} source="name" />
    </SimpleShowLayout>
  </Show>
);

export default CategoryShow;
