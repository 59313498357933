import React from 'react';
import { TopToolbar } from 'react-admin';

const NoCreateActions = ({ displayedFilters, filters, filterValues, resource, showFilter }) => (
  <TopToolbar>
    {filters && React.cloneElement(filters, {
      resource,
      showFilter,
      displayedFilters,
      filterValues,
      context: 'button',
    })}
  </TopToolbar>
);

export default NoCreateActions;
