import React from 'react';
import { HydraAdmin } from '@api-platform/admin';
import authProvider from './auth/authProvider';
import apiDocumentationParser from './auth/apiDocumentationParser';
import dataProvider from './auth/dataProvider';
import { createMuiTheme } from '@material-ui/core/styles';
import { Resource } from 'react-admin';
import AdminList from './components/list/AdminList';
import AdminShow from './components/show/AdminShow';
import AdminCreate from './components/create/AdminCreate';
import AdminEdit from './components/edit/AdminEdit';
import CategoryList from './components/list/CategoryList';
import CategoryShow from './components/show/CategoryShow';
import CategoryCreate from './components/create/CategoryCreate';
import CategoryEdit from './components/edit/CategoryEdit';
import ConversationList from './components/list/ConversationList';
import ConversationShow from './components/show/CoversationShow';
import ConversationEdit from './components/edit/ConversationEdit';
import CustomerList from './components/list/CustomerList';
import CustomerShow from './components/show/CustomerShow';
import CustomerCreate from './components/create/CustomerCreate';
import ExpertEdit from './components/edit/ExpertEdit';
import ExpertCreate from './components/create/ExpertCreate';
import ExpertShow from './components/show/ExpertShow';
import ExpertList from './components/list/ExpertList';
import CharacterList from './components/list/CharacterList';
import CharacterCreate from './components/create/CharacterCreate';
import CharacterShow from "./components/show/CharacterShow";
import CharacterEdit from "./components/edit/CharacterEdit";
import InvoiceList from './components/list/InvoiceList';
import InvoiceShow from './components/show/InvoiceShow';
import PackageEdit from './components/edit/PackageEdit';
import PackageCreate from './components/create/PackageCreate';
import PackageShow from './components/show/PackageShow';
import PackageList from './components/list/PackageList';
import PageContentList from './components/list/PageContentList';
import PageContentShow from './components/show/PageContentShow';
import PageContentCreate from './components/create/PageContentCreate';
import PageContentEdit from './components/edit/PageContentEdit';
import ReadingEdit from './components/edit/ReadingEdit';
import ReadingShow from './components/show/ReadingShow';
import ReadingList from './components/list/ReadingList';
import CustomerEdit from './components/edit/CustomerEdit';

const theme = createMuiTheme({
  palette: {
    type: 'light',
    primary: { main: '#0cd1e8' },
    secondary: { main: '#846FAA' },
  },
});

export default props => (
  <HydraAdmin
    apiDocumentationParser={ apiDocumentationParser }
    dataProvider={ dataProvider }
    authProvider={ authProvider }
    entrypoint={ process.env.REACT_APP_API_ENTRYPOINT }
    theme={ theme }
  >
    <Resource name="admins" list={AdminList} show={AdminShow} create={AdminCreate} edit={AdminEdit} />
    <Resource name="categories" list={CategoryList} show={CategoryShow} create={CategoryCreate} edit={CategoryEdit} />
    <Resource name="conversations" list={ConversationList} show={ConversationShow} edit={ConversationEdit} />
    <Resource name="customers" list={CustomerList} show={CustomerShow} create={CustomerCreate} edit={CustomerEdit} />
    <Resource name="experts" list={ExpertList} show={ExpertShow} create={ExpertCreate} edit={ExpertEdit} />
    <Resource name="characters" list={CharacterList} show={CharacterShow} create={CharacterCreate} edit={CharacterEdit} />
    <Resource name="invoices" list={InvoiceList} show={InvoiceShow} />
    <Resource name="packages" list={PackageList} show={PackageShow} create={PackageCreate} edit={PackageEdit} />
    <Resource name="page_contents" list={PageContentList} show={PageContentShow} create={PageContentCreate} edit={PageContentEdit} />
    <Resource name="readings" list={ReadingList} show={ReadingShow} edit={ReadingEdit} />
  </HydraAdmin>
);


