import { Edit, SimpleForm, TextInput } from 'react-admin';
import React from 'react';
import RecordTitle from '../RecordTitle';
import EditActions from '../actions/EditActions';

const CategoryEdit = props => (
  <Edit
    actions={<EditActions />}
    title={<RecordTitle type="Category" />}
    {...props}
  >
    <SimpleForm>
      <TextInput source="name" />
    </SimpleForm>
  </Edit>
);

export default CategoryEdit;
