import React from 'react';
import { Datagrid, EditButton, List, ShowButton, TextField } from 'react-admin';

const CharacterList = props => (
  <List {...props}>
    <Datagrid>
      <TextField source="name" />
      <TextField source="language" />
      <TextField source="shortCode" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

export default CharacterList;
