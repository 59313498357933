import React from "react";
import { BooleanInput, Filter, NumberInput, ReferenceInput, SelectInput } from 'react-admin';
import StopReasonInput from '../StopReasonInput';

const ReadingFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput label="Conversation" source="conversation" reference="conversations">
      <SelectInput optionText="id" />
    </ReferenceInput>
    <NumberInput source="minuteRate" />
    <BooleanInput source="paused" />
    <NumberInput source="balanceUsed" />
    <NumberInput source="minutesUsed" />
    <StopReasonInput />
  </Filter>
);

export default ReadingFilter;
