import React from "react";
import { Filter, TextInput } from 'react-admin';

const PageContentFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Slug" source="slug" />
    <TextInput label="Title" source="title" />
    <TextInput label="Hidden" source="hidden" />
  </Filter>
);

export default PageContentFilter;
