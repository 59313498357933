import React from "react";
import { Filter, TextInput } from 'react-admin';

const AdminFilter = (props) => (
  <Filter {...props}>
    <TextInput label="E-mail" source="email" />
  </Filter>
);

export default AdminFilter;
