import { BooleanInput, DateInput, Edit, NumberInput, ReferenceInput, SelectInput, SimpleForm } from 'react-admin';
import React from 'react';
import RecordTitle from '../RecordTitle';
import EditActions from '../actions/EditActions';
import StopReasonInput from '../StopReasonInput';

const ReadingEdit = props => (
  <Edit
    actions={<EditActions />}
    title={<RecordTitle type="Reading" />}
    {...props}
  >
    <SimpleForm>
      <ReferenceInput disabled label="Conversation" source="conversation" reference="conversations">
        <SelectInput optionText="id" />
      </ReferenceInput>
      <NumberInput source="minuteRate" />
      <BooleanInput source="paused" />
      <NumberInput disabled source="balanceUsed" />
      <NumberInput disabled source="minutesUsed" />
      <DateInput disabled source="finishedAt" />
      <StopReasonInput />
    </SimpleForm>
  </Edit>
);

export default ReadingEdit;
