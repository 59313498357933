import { DateField, EmailField, SimpleShowLayout, Show, TextField } from 'react-admin';
import React from 'react';
import RecordTitle from '../RecordTitle';
import ShowActions from '../actions/ShowActions';

const CustomerShow = props => (
  <Show
    title={<RecordTitle type="Customer" />} {...props}
    actions={<ShowActions />}
  >
    <SimpleShowLayout>
      <TextField source="name" />
      <EmailField source="email" />
      <TextField source="balance" />
      <TextField source="minutesToUse" />
      <TextField source="gender" />
      <TextField source="faceBookUserId" />
      <TextField source="googleUserId" />
      <DateField source="createdAt" />
    </SimpleShowLayout>
  </Show>
);

export default CustomerShow;
