import { BooleanField, ChipField, DateField, ImageField, ReferenceManyField, RichTextField, SimpleShowLayout, SingleFieldList, Show, TextField } from 'react-admin';
import React from 'react';
import RecordTitle from '../RecordTitle';
import ShowActions from '../actions/ShowActions';

const ExpertShow = props => (
  <Show
    title={<RecordTitle type="Expert" />} {...props}
    actions={<ShowActions />}
  >
    <SimpleShowLayout>
      <TextField source="name" />
      <TextField source="email" />
      <ReferenceManyField label="Categories" reference="categories" target="experts">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceManyField>
      <ReferenceManyField label="Characters" reference="characters" target="experts">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceManyField>
      <TextField source="minuteRate" />
      <TextField source="totalRating" />
      <TextField source="summary" />
      <RichTextField source="description" />
      <BooleanField source="available" />
      <ImageField source="avatarUrl" />
      <TextField source="country" />
      <TextField source="gender" />
      <DateField source="createdAt" />
    </SimpleShowLayout>
  </Show>
);

export default ExpertShow;
