import React from 'react';
import { BooleanField, Datagrid, DateField, EditButton, List, ShowButton, TextField } from 'react-admin';
import ExpertFilter from '../filter/ExpertFilter';
import FilteredLink from '../FilteredLink';

const ExpertList = props => (
  <List {...props} filters={<ExpertFilter />}>
    <Datagrid>
      <TextField source="name" />
      <TextField source="email" />
      <TextField source="minuteRate" />
      <TextField source="totalRating" />
      <BooleanField source="available" />
      <TextField source="gender" />
      <DateField source="createdAt" />
      <FilteredLink filterBy="expert" link="conversations" />
      <FilteredLink filterBy="expert" link="readings" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

export default ExpertList;
