import React from 'react';
import { BooleanField, Datagrid, DateField, List, ReferenceField, ShowButton, TextField } from 'react-admin';
import InvoiceFilter from '../filter/InvoiceFilter';

const InvoiceList = props => (
  <List {...props} filters={<InvoiceFilter />} actions={null}>
    <Datagrid>
      <ReferenceField source="customer" reference="customers" sortable={false}>
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="package" reference="packages" sortable={false}>
        <TextField source="appleStoreProductName" />
      </ReferenceField>
      <TextField source="amountPaid" />
      <BooleanField source="success" />
      <DateField source="createdAt" />
      <ShowButton />
    </Datagrid>
  </List>
);

export default InvoiceList;
