import React from 'react';
import { BooleanField, Datagrid, DateField, EditButton, List, ReferenceField, ShowButton, TextField } from 'react-admin';
import NoCreateActions from '../actions/NoCreateActions';
import ConversationFilter from '../filter/ConversationFilter';
import FilteredLink from '../FilteredLink';

const ConversationList = props => (
  <List {...props} filters={<ConversationFilter />} actions={<NoCreateActions />}>
    <Datagrid>
      <ReferenceField source="expert" reference="experts" sortable={false}>
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="customer" reference="customers" sortable={false}>
        <TextField source="name" />
      </ReferenceField>
      <TextField source="firstMercureMessageId" />
      <BooleanField source="affordable" sortable={false} />
      <TextField source="availableMinutes" sortable={false} />
      <DateField source="updatedAt" />
      <FilteredLink filterBy="conversation" link="readings" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

export default ConversationList;
