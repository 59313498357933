import React from 'react';
import { List, Datagrid, EditButton, ShowButton, TextField } from 'react-admin';
import CategoryFilter from '../filter/CategoryFilter';

const CategoryList = props => (
  <List {...props} filters={<CategoryFilter />}>
    <Datagrid>
      <TextField source="name" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

export default CategoryList;
