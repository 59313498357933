import React from 'react';
import { List, Datagrid, DateField, EditButton, EmailField, ShowButton, TextField } from 'react-admin';
import CustomerFilter from '../filter/CustomerFilter';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import ReceiptIcon from '@material-ui/icons/Receipt';
import FilteredLink from '../FilteredLink';

const CustomerList = props => (
  <List {...props} filters={<CustomerFilter />}>
    <Datagrid>
      <TextField source="name" sortable={false} />
      <EmailField source="email" />
      <TextField source="balance" />
      <TextField source="minutesToUse" />
      <DateField source="createdAt" />
      <FilteredLink filterBy="customer" link="conversations" icon={<ChatBubbleOutlineIcon />} />
      <FilteredLink filterBy="customer" link="readings" />
      <FilteredLink filterBy="customer" link="invoices" icon={<ReceiptIcon />} />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

export default CustomerList;
