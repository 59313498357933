import { BooleanField, DateField, ReferenceField, SimpleShowLayout, Show, TextField } from 'react-admin';
import React from 'react';
import RecordTitle from '../RecordTitle';
import ShowActions from '../actions/ShowActions';

const ConversationShow = props => (
  <Show
    title={<RecordTitle type="Conversation" property="id" />} {...props}
    actions={<ShowActions />}
  >
    <SimpleShowLayout>
      <ReferenceField source="expert" reference="experts">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="customer" reference="customers">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="firstMercureMessageId" />
      <BooleanField source="affordable" />
      <TextField source="availableMinutes" />
      <DateField source="updatedAt" />
    </SimpleShowLayout>
  </Show>
);

export default ConversationShow;
