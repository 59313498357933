import { Create, NumberInput, SimpleForm, TextInput, PasswordInput } from 'react-admin';
import React from 'react';
import GenderInput from '../GenderInput';

const CustomerCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="email" />
      <NumberInput source="balance" />
      <NumberInput source="minutesToUse" />
      <GenderInput />
      <TextInput source="faceBookUserId" />
      <TextInput source="googleUserId" />
      <PasswordInput source="password" />
    </SimpleForm>
  </Create>
);

export default CustomerCreate;
