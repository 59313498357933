import { BooleanInput, Edit, NumberInput, PasswordInput, ReferenceArrayInput, SelectArrayInput, SimpleForm, TextInput } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import React from 'react';
import RecordTitle from '../RecordTitle';
import EditActions from '../actions/EditActions';
import GenderInput from '../GenderInput';

const ExpertEdit = props => (
  <Edit
    actions={<EditActions />}
    title={<RecordTitle type="Expert" property="name" />}
    {...props}
  >
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="email" />
      <ReferenceArrayInput label="Categories" reference="categories" source="categories">
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
      <NumberInput source="minuteRate" />
      <NumberInput source="totalRating" />
      <TextInput multiline source="summary" />
      <RichTextInput source="description" />
      <BooleanInput source="available" />
      <TextInput source="avatarUrl" />
      <TextInput source="country" />
      <PasswordInput source="password" />
      <GenderInput />
    </SimpleForm>
  </Edit>
);

export default ExpertEdit;
