import { Create, ReferenceArrayInput, SelectArrayInput, SimpleForm, TextInput } from 'react-admin';
import React from 'react';

const CharacterCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="shortCode" />
      <TextInput source="language" />
      <TextInput source="avatarUrl" />
      <ReferenceArrayInput label="Experts" reference="experts" source="experts">
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
    </SimpleForm>
  </Create>
);

export default CharacterCreate;
