import { DeleteButton, ShowButton, ListButton } from 'react-admin';
import TopToolbar from '@material-ui/core/Toolbar';
import React from 'react';

const EditActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
    <ShowButton basePath={basePath} record={data} />
    <DeleteButton basePath={basePath} record={data} resource={resource} />
  </TopToolbar>
);

export default EditActions;
