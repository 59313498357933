import { BooleanInput, Edit, SimpleForm, TextInput } from 'react-admin';
import React from 'react';
import RecordTitle from '../RecordTitle';
import EditActions from '../actions/EditActions';
import RichTextInput from 'ra-input-rich-text';

const PageContentEdit = props => (
  <Edit
    actions={<EditActions />}
    title={<RecordTitle type="PageContent" />}
    {...props}
  >
    <SimpleForm>
      <TextInput source="slug" />
      <TextInput source="title" />
      <BooleanInput source="hidden" />
      <RichTextInput source="html" />
    </SimpleForm>
  </Edit>
);

export default PageContentEdit;
