import { BooleanField, SimpleShowLayout, Show, TextField } from 'react-admin';
import React from 'react';
import RecordTitle from '../RecordTitle';
import ShowActions from '../actions/ShowActions';

const PackageShow = props => (
  <Show
    title={<RecordTitle type="Package" property="appleStoreProductName" />} {...props}
    actions={<ShowActions />}
  >
    <SimpleShowLayout>
      <TextField source="appleStoreProductName" />
      <TextField source="description" />
      <BooleanField source="hidden" />
      <TextField source="price" />
      <TextField source="minutesToUse" />
      <TextField source="discount" />
    </SimpleShowLayout>
  </Show>
);

export default PackageShow;
