import React from 'react';
import { SelectInput } from 'react-admin';

const StopReasonInput = () => (
  <SelectInput source="stopReason" choices={[
    { id: null, name: 'Not set' },
    { id: 'disconnected', name: 'disconnected' },
    { id: 'customer', name: 'customer' },
    { id: 'expert', name: 'expert' },
    { id: 'out-of-balance', name: 'out-of-balance' },
    { id: 'timeout', name: 'timeout' },
    { id: 'customer', name: 'customer' },
  ]} />
);

export default StopReasonInput;
