import { Edit, NumberInput, SimpleForm, TextInput, PasswordInput } from 'react-admin';
import React from 'react';
import RecordTitle from '../RecordTitle';
import GenderInput from '../GenderInput';
import EditActions from '../actions/EditActions';

const CustomerEdit = props => (
  <Edit
    actions={<EditActions />}
    title={<RecordTitle type="Customer" />}
    {...props}
  >
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="email" />
      <NumberInput source="balance" />
      <NumberInput source="minutesToUse" />
      <GenderInput />
      <TextInput source="faceBookUserId" />
      <TextInput source="googleUserId" />
      <PasswordInput source="password" />
    </SimpleForm>
  </Edit>
);

export default CustomerEdit;
