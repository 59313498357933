import React from 'react';
import { List, Datagrid, EditButton, EmailField, ShowButton, TextField, DateField } from 'react-admin';
import AdminFilter from '../filter/AdminFilter';

const AdminList = props => (
  <List {...props} filters={<AdminFilter />}>
    <Datagrid>
      <TextField source="name" />
      <EmailField source="email" />
      <DateField source="createdAt" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

export default AdminList;
