import parseHydraDocumentation from '@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation';
import getHeaders from './getHeaders';
import React from 'react';
import { Redirect } from "react-router-dom";

const apiDocumentationParser = (entryPoint) => parseHydraDocumentation(
  entryPoint,
  { headers: getHeaders() }
).then(
  ({ api }) => ({ api }),
  (result) => {
    const HTTP_STATUS_UNAUTHORIZED = 401;
    if (result.status === HTTP_STATUS_UNAUTHORIZED) {
      window.localStorage.removeItem('token');
      return Promise.resolve({
        api: result.api,
        customRoutes: [{
          props: {
            path: '/',
            render: () => <Redirect to={'/login'}/>,
          },
        }],
      });
    }
    return Promise.reject(result);
  },
);

export default apiDocumentationParser;
