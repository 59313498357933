import { Edit, ReferenceArrayInput, SelectArrayInput, SimpleForm, TextInput } from 'react-admin';
import React from 'react';
import RecordTitle from '../RecordTitle';
import EditActions from '../actions/EditActions';

const CharacterEdit = props => (
  <Edit
    actions={<EditActions />}
    title={<RecordTitle type="Character" property="name" />}
    {...props}
  >
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="shortCode" />
      <ReferenceArrayInput label="Experts" reference="experts" source="experts">
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
      <TextInput source="language" />
      <TextInput source="avatarUrl" />
    </SimpleForm>
  </Edit>
);

export default CharacterEdit;
