import { Edit, ReferenceInput, SelectInput, SimpleForm, TextInput } from 'react-admin';
import React from 'react';
import RecordTitle from '../RecordTitle';
import EditActions from '../actions/EditActions';

const ConversationEdit = props => (
  <Edit
    actions={<EditActions />}
    title={<RecordTitle type="Conversation" property="id" />}
    {...props}
  >
    <SimpleForm>
      <ReferenceInput label="Expert" source="expert" reference="experts">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput label="Mercure message" source="firstMercureMessageId" />
    </SimpleForm>
  </Edit>
);

export default ConversationEdit;
