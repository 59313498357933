import { Create, NumberInput, PasswordInput, ReferenceArrayInput, SelectArrayInput, SimpleForm, TextInput } from 'react-admin';
import React from 'react';
import GenderInput from '../GenderInput';
import RichTextInput from 'ra-input-rich-text';

const ExpertCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="email" />
      <ReferenceArrayInput label="Categories" reference="categories" source="categories">
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
      <NumberInput source="minuteRate" />
      <NumberInput source="totalRating" />
      <TextInput multiline source="summary" />
      <RichTextInput source="description" />
      <TextInput source="avatarUrl" />
      <TextInput source="country" />
      <PasswordInput source="password" />
      <GenderInput />
    </SimpleForm>
  </Create>
);

export default ExpertCreate;
