import React from "react";
import { Filter, TextInput } from 'react-admin';

const CustomerFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Name" source="name" />
    <TextInput label="Email" source="email" />
    <TextInput label="Facebook ID" source="faceBookUserId" />
    <TextInput label="Google ID" source="googleUserId" />
  </Filter>
);

export default CustomerFilter;
