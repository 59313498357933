import React from 'react';
import { BooleanField, Datagrid, DateField, EditButton, List, ReferenceField, ShowButton, TextField } from 'react-admin';
import ReadingFilter from '../filter/ReadingFilter';
import NoCreateActions from '../actions/NoCreateActions';

const ReadingList = props => (
  <List {...props} filters={<ReadingFilter />} actions={<NoCreateActions />}>
    <Datagrid>
      <ReferenceField source="conversation" reference="conversations" sortable={false}>
        <TextField source="id" />
      </ReferenceField>
      <TextField source="minuteRate" />
      <DateField source="createdAt" />
      <BooleanField source="paused" />
      <TextField source="balanceUsed" />
      <TextField source="minutesUsed" />
      <TextField source="stopReason" />
      <DateField source="finishedAt" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

export default ReadingList;
