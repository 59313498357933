import React from 'react';
import { Button, Link } from 'react-admin';
import ListIcon from '@material-ui/icons/List';

const FilteredLink = ({ record, link, filterBy, icon = <ListIcon />, title = null }) => (
  <Link to={{
    pathname: `/${link}`,
    search: `filter={"${filterBy}": "${record.id}"}`,
    limit: 20,
    offset: 0,
  }}>
    <Button
      label={ title ? title : link }
      startIcon={icon}
      style={{textTransform: 'capitalize'}}
    />
  </Link>
);

export default FilteredLink;
