import { BooleanField, DateField, ReferenceField, SimpleShowLayout, Show, TextField } from 'react-admin';
import React from 'react';
import RecordTitle from '../RecordTitle';
import ShowActions from '../actions/ShowActions';

const ReadingShow = props => (
  <Show
    title={<RecordTitle type="Reading" />} {...props}
    actions={<ShowActions />}
  >
    <SimpleShowLayout>
      <ReferenceField source="conversation" reference="conversations">
        <TextField source="id" />
      </ReferenceField>
      <TextField source="minuteRate" />
      <DateField source="createdAt" />
      <BooleanField source="paused" />
      <TextField source="balanceUsed" />
      <TextField source="minutesUsed" />
      <TextField source="stopReason" />
      <DateField source="finishedAt" />
    </SimpleShowLayout>
  </Show>
);

export default ReadingShow;
