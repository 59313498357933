import { BooleanInput, Create, NumberInput, SimpleForm, TextInput } from 'react-admin';
import React from 'react';

const PackageCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="appleStoreProductName" />
      <TextInput source="description" />
      <BooleanInput source="hidden" />
      <NumberInput source="price" />
      <NumberInput source="minutesToUse" />
      <TextInput source="discount" />
    </SimpleForm>
  </Create>
);

export default PackageCreate;
