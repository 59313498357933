import { BooleanField, SimpleShowLayout, DateField, ReferenceField, Show, TextField } from 'react-admin';
import React from 'react';
import RecordTitle from '../RecordTitle';

const InvoiceShow = props => (
  <Show
    title={<RecordTitle type="Invoice" property="id" />} {...props}
    actions={null}
  >
    <SimpleShowLayout>
      <ReferenceField source="customer" reference="customers">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="package" reference="packages">
        <TextField source="appleStoreProductName" />
      </ReferenceField>
      <TextField source="amountPaid" />
      <BooleanField source="success" />
      <TextField source="transactionId" />
      <TextField source="receipt" />
      <DateField source="createdAt" />
    </SimpleShowLayout>
  </Show>
);

export default InvoiceShow;
