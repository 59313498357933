import { ChipField, ImageField, ReferenceManyField, SimpleShowLayout, SingleFieldList, Show, TextField } from 'react-admin';
import React from 'react';
import RecordTitle from '../RecordTitle';
import ShowActions from '../actions/ShowActions';

const CharacterShow = props => (
  <Show
    title={<RecordTitle type="Character" />} {...props}
    actions={<ShowActions />}
  >
    <SimpleShowLayout>
      <TextField source="name" />
      <TextField source="shortCode" />
      <ReferenceManyField label="Experts" reference="experts" target="experts">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceManyField>
      <TextField source="language" />
      <ImageField source="avatarUrl" />
    </SimpleShowLayout>
  </Show>
);

export default CharacterShow;
