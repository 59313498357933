import React from 'react';
import { BooleanField, Datagrid, EditButton, List, ShowButton, TextField } from 'react-admin';
import PageContentFilter from '../filter/PageContentFilter';

const PageContentList = props => (
  <List {...props} filters={<PageContentFilter />}>
    <Datagrid>
      <TextField source="slug" />
      <TextField source="title" />
      <BooleanField source="hidden" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

export default PageContentList;
