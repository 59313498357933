import { Create, SimpleForm, TextInput } from 'react-admin';
import React from 'react';

const AdminCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="email" />
      <TextInput source="password" />
    </SimpleForm>
  </Create>
);

export default AdminCreate;
