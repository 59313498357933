import { BooleanInput, Create, SimpleForm, TextInput } from 'react-admin';
import React from 'react';
import RichTextInput from 'ra-input-rich-text';

const PageContentCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="slug" />
      <TextInput source="title" />
      <BooleanInput source="hidden" />
      <RichTextInput source="html" />
    </SimpleForm>
  </Create>
);

export default PageContentCreate;
