import React from "react";
import { BooleanInput, Filter, TextInput } from 'react-admin';

const ExpertFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Name" source="name" />
    <TextInput label="Email" source="email" />
    <TextInput label="Country" source="country" />
    <BooleanInput label="Is available" source="available" />
  </Filter>
);

export default ExpertFilter;
