import React from "react";
import { Filter, TextInput, ReferenceInput, SelectInput } from 'react-admin';

const CategoryFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Name" source="name" />
    <ReferenceInput label="Expert" source="experts" reference="experts">
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

export default CategoryFilter;
