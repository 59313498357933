import { hydraDataProvider, fetchHydra as baseFetchHydra } from '@api-platform/admin';
import apiDocumentationParser from './apiDocumentationParser';
import getHeaders from './getHeaders';

const fetchHydra = (url, options = {}) => {
  if (options && options.body && (typeof options.body !== 'string')) {
    options.body = JSON.stringify(options.body);
  }

  return baseFetchHydra(url, {
    ...options,
    headers: getHeaders(),
  });
};

const dataProvider = hydraDataProvider(
  process.env.REACT_APP_API_ENTRYPOINT,
  fetchHydra,
  apiDocumentationParser
);

export default dataProvider;
