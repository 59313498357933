const HTTP_UNAUTHORIZED = 401;

const authProvider = {
  login: ({ username, password }) =>  {
    const loginEndpointUrl = process.env.REACT_APP_API_ENTRYPOINT + '/login';
    const defaultHeaders = { 'Content-Type': 'application/json' };
    const request = new Request(loginEndpointUrl, {
      method: 'POST',
      body: JSON.stringify({ identification: username, password }),
      headers: new Headers(defaultHeaders),
    });

    return fetch(request)
      .then((response) => {
        if (!response.ok) {
          return Promise.reject(response.statusText);
        }
        return response.json();
      })
      .then((response) => {
        localStorage.setItem('token', response.token);
      });
  },

  logout: () => {
    localStorage.removeItem('token');
    return Promise.resolve();
  },

  checkError: (error) => {
    if (error.status === HTTP_UNAUTHORIZED) {
      localStorage.removeItem('token');
      return Promise.reject();
    }
    return Promise.resolve();
  },

  checkAuth: () => localStorage.getItem('token')
    ? Promise.resolve()
    : Promise.reject(),

  getPermissions: params => Promise.resolve(),
};

export default authProvider;
