import { BooleanField, RichTextField, SimpleShowLayout, Show, TextField } from 'react-admin';
import React from 'react';
import RecordTitle from '../RecordTitle';
import ShowActions from '../actions/ShowActions';

const PageContentShow = props => (
  <Show
    title={<RecordTitle type="PageContent" property="slug" />} {...props}
    actions={<ShowActions />}
  >
    <SimpleShowLayout>
      <TextField source="slug" />
      <TextField source="title" />
      <BooleanField source="hidden" />
      <RichTextField source="html" />
    </SimpleShowLayout>
  </Show>
);

export default PageContentShow;
